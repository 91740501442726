import type {
  Customer,
  CustomerAccessToken,
  CustomerQuery,
} from "@/graphql/generated/graphql-operations";
import useLocalStorage from "@/js/composables/useLocalStorage";
import Client, { Environment, models } from "@/js/lib/membership-client.encore";
import * as Sentry from "@sentry/vue";
import dayjs from "dayjs";

type Subscription = models.Subscription;
type Call = models.Call;

interface SubscriptionState {
  isActiveSubscriber: boolean;
  subscription: Subscription | null;
  book_a_vet_call: boolean;
  call: Call | null;
  loading: boolean;
}

interface CustomerStore {
  customer: Customer;
  customerAccessToken: CustomerAccessToken;
}

const MESSAGING_WINDOW_DURATION = 10;
const ACTIVE_MEMBERSHIP_TAGS = ["membership_status_tmp:active"];
const MONTHLY_SUBSCRIPTION_PRODUCT_TITLE = "Unlimited Vet Advice";

const { get } = useLocalStorage();
const { IS_STAGING, customer: themeCustomer } = useTheme();
// Use localStorage for customer data to ensure availability in non-Vue contexts
const storefrontCustomer = useLocalStorage().get<CustomerQuery | null>(
  "customer"
);

const customer = get<CustomerStore>("customer");
const customerAccessToken = customer?.customerAccessToken?.accessToken;

// Client initialization
const encoreMembershipClient = new Client(
  Environment(IS_STAGING ? "dutch-staging" : "dutch-prod")
);

const subscriptionState = reactive<SubscriptionState>({
  isActiveSubscriber: false,
  subscription: null,
  book_a_vet_call: false,
  call: null,
  loading: false,
});

const remainingDaysToMessage = computed(() => {
  if (!subscriptionState.call?.end_time) return 0;

  const callEndDate = dayjs(subscriptionState.call.end_time);
  const currentDate = dayjs();
  const daysSinceCall = currentDate.diff(callEndDate, "day");

  return Math.max(MESSAGING_WINDOW_DURATION - daysSinceCall, 0);
});

const isSubscriptionExpiring = computed(
  () => !!subscriptionState.subscription?.expiring_at
);

const isSubscriptionExpired = computed(
  () =>
    !subscriptionState.subscription?.expiring_at &&
    !subscriptionState.isActiveSubscriber
);

const isSubscriptionReady = computed(
  () =>
    subscriptionState.isActiveSubscriber &&
    Boolean(subscriptionState.subscription?.recharge_customer_id)
);

const hasActiveSubscriberTag = computed(() =>
  ACTIVE_MEMBERSHIP_TAGS.some(
    (key) =>
      themeCustomer?.tags.includes(key) ||
      storefrontCustomer?.customer?.tags.includes(key)
  )
);

const isMonthlySubscription = computed(
  () =>
    subscriptionState.subscription?.product_title ===
      MONTHLY_SUBSCRIPTION_PRODUCT_TITLE &&
    !subscriptionState.subscription?.annual
);

const fetchMembershipData = async () => {
  if (!themeCustomer?.id || !customerAccessToken) return;

  try {
    const response =
      await encoreMembershipClient.memberships.GetMembershipByShopifyCustomerID(
        themeCustomer.id.toString(),
        {
          HeaderAuthorization: customerAccessToken,
        }
      );

    return response;
  } catch (error: unknown) {
    Sentry.captureException(error);
  }
};

const handleTemporaryMemberAccess = () => {
  if (isSubscriptionReady.value) return;

  subscriptionState.isActiveSubscriber = hasActiveSubscriberTag.value;

  if (!subscriptionState.book_a_vet_call) {
    subscriptionState.book_a_vet_call = true;
  }
};

const refreshMembershipStatus = async () => {
  subscriptionState.loading = true;

  try {
    const membership = await fetchMembershipData();

    subscriptionState.isActiveSubscriber = membership?.active || false;
    subscriptionState.subscription = membership?.subscription || null;
    subscriptionState.book_a_vet_call = membership?.book_a_vet_call || false;
    subscriptionState.call = membership?.call || null;

    handleTemporaryMemberAccess();
  } catch (error) {
    console.error(error);
  } finally {
    subscriptionState.loading = false;
  }
};

const toggleSubscriptionAutoRenew = async (action: "enable" | "disable") => {
  if (!customerAccessToken || !themeCustomer?.id) return;

  try {
    subscriptionState.loading = true;
    const renewalAction =
      action === "enable"
        ? encoreMembershipClient.memberships.EnableMembershipRenewal.bind(
            encoreMembershipClient.memberships
          )
        : encoreMembershipClient.memberships.DisableMembershipRenewal.bind(
            encoreMembershipClient.memberships
          );

    const result = await renewalAction(themeCustomer?.id.toString(), {
      HeaderAuthorization: customerAccessToken,
    });

    return result;
  } catch (error: unknown) {
    Sentry.captureException(error);
  } finally {
    subscriptionState.loading = false;
  }
};

const initializeMembership = async () => {
  if (subscriptionState.loading) return;
  await refreshMembershipStatus();
};

initializeMembership();

export default function useSubscription() {
  return {
    ...toRefs(subscriptionState),
    daysLeftToMessage: remainingDaysToMessage,
    isSubscriptionExpiring,
    isSubscriptionExpired,
    isMonthlySubscription,
    isSubscriptionReady,
    toggleSubscriptionAutoRenew,
    fetchSubscriptionStatus: refreshMembershipStatus,
  };
}
